import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  salidas: [],
  totalCount: 0,
  loading: false,
  dataTipoSalidaGraphic: [],
  dataCatSalidaGraphic: [],
  topDemanda: [],
}

export const salSlice = createSlice({
  name: 'sal',
  initialState,
  reducers: {
    setSalidas: (state, action) => {
      state.salidas = action.payload
    },
    setSalidasTopDemanda: (state, action) => {
      state.topDemanda = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setDataTipoSalida: (state, action) => {
      state.dataTipoSalidaGraphic = action.payload
    },
    setDataCatSalida: (state, action) => {
      state.dataCatSalidaGraphic = action.payload
    }
  },
})

export const {
  setSalidasTopDemanda,
  setSalidas,
  setTotalCount,
  setLoading,
  setDataTipoSalida,
  setDataCatSalida,
} = salSlice.actions
export default salSlice.reducer
